<template>
  <div>
    <b-card
      no-body
    >
      <b-card-header class="pb-50">
        <h5>
          {{ $t('futures.relatorio.fechamento.titleFiltros') }}
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('futures.relatorio.fechamento.dataInicial') }}</label>
            <flat-pickr
              v-model="dataBuscaFilter.inicio"
              class="form-control"
              :config="{ altInput: true, enableTime: false, altFormat: 'd/m/Y', locale: Portuguese}"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('futures.relatorio.fechamento.dataFinal') }}</label>
            <flat-pickr
              v-model="dataBuscaFilter.fim"
              class="form-control"
              :config="{ altInput: true, enableTime: false, altFormat: 'd/m/Y', locale: Portuguese}"
            />
          </b-col>

          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('spot.relatorio.labelCoin') }}</label>
            <b-form-input
              id="coin"
              v-model="dataBuscaFilter.coin"
            />
          </b-col>

          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label style="visibility:hidden">{{ $t('futures.relatorio.fechamento.acao') }}</label>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="form-control"
              @click.prevent="buscarFiltro"
            >
              {{ $t('futures.relatorio.fechamento.pesquisarBtn') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Statistics -->
    <b-overlay
      :show="loadingOverlayGrid"
      rounded="sm"
    >
      <b-card
        no-body
        class="card-statistics"
      >
        <b-card-header>
          <b-card-title>
            <div class="mb-1">
              {{ $t('futures.relatorio.fechamento.lucroRealizadoLiquido') }}
            </div>
            <h3 class="mb-1 font-weight-bolder">
              $ {{ resumo.lucroLiquido.toFixed(2) }}
            </h3>
          </b-card-title>
          <div
            class="heading-elements"
            style="top:-20px"
          >
            <ul class="list-inline mb-0">
              <li>
                <a
                  id="fut-rel-fechamento-refresh"
                  data-action="reload"
                  @click="refresh()"
                >
                  <feather-icon
                    icon="RotateCwIcon"
                    size="14"
                  />
                </a>
              </li>
            </ul>
          </div>
        </b-card-header>

        <b-card-body>
          <b-row>
            <b-col
              cols="6"
              md="2"
              class="mb-2 mb-xl-0"
            >
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-tooltip.hover.top="$t('futures.relatorio.fechamento.tooltipOperacoes')"
                variant="flat-primary"
                class="btn-icon"
                block
              >
                <b-media no-body>
                  <b-media-aside class="mt-1">
                    <feather-icon
                      size="24"
                      icon="WifiIcon"
                    />
                  </b-media-aside>
                  <b-media-body
                    :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start ml-1' : '' }`"
                  >
                    <div>
                      <h3 class="font-weight-bolder mb-0">
                        {{ resumo.total }}
                      </h3>
                      <p class="mb-0 text-secondary">
                        {{ $t('futures.relatorio.fechamento.operacoes') }}
                      </p>
                    </div>
                  </b-media-body>
                </b-media>
              </b-button>
            </b-col>
            <b-col
              cols="6"
              md="2"
              class="mb-2 mb-xl-0"
            >
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-tooltip.hover.top="$t('futures.relatorio.fechamento.tooltipTotalPnl')"
                variant="flat-primary"
                class="btn-icon"
                block
              >
                <b-media no-body>
                  <b-media-aside class="mt-1">
                    <feather-icon
                      size="24"
                      icon="DollarSignIcon"
                      class="text-success"
                    />
                  </b-media-aside>
                  <b-media-body
                    :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start  ml-1' : '' }`"
                  >
                    <div>
                      <h3 class="font-weight-bolder mb-0">
                        {{ resumo.totalPnl.toFixed(2) }}
                      </h3>
                      <p class="mb-0 text-secondary">
                        {{ $t('futures.relatorio.fechamento.totalPnl') }}
                      </p>
                    </div>
                  </b-media-body>
                </b-media>
              </b-button>
            </b-col>
            <b-col
              cols="6"
              md="2"
              class="mb-2 mb-xl-0"
            >
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-tooltip.hover.top="$t('futures.relatorio.fechamento.tooltipSomaRoe')"
                variant="flat-primary"
                class="btn-icon"
                block
              >
                <b-media no-body>
                  <b-media-aside class="mt-1">
                    <feather-icon
                      size="24"
                      icon="TrendingUpIcon"
                      class="text-success"
                    />
                  </b-media-aside>
                  <b-media-body
                    :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start  ml-1' : '' }`"
                  >
                    <div>
                      <h3 class="font-weight-bolder mb-0">
                        {{ resumo.totalPercentRoe.toFixed(2) }}
                      </h3>
                      <p class="mb-0 text-secondary">
                        {{ $t('futures.relatorio.fechamento.somaRoe') }} <small class="text-secondary">(%)</small>
                      </p>
                    </div>
                  </b-media-body>
                </b-media>
              </b-button>
            </b-col>
            <b-col
              cols="6"
              md="2"
              class="mb-2 mb-xl-0"
            >
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-tooltip.hover.top="$t('futures.relatorio.fechamento.tooltipTaxa')"
                variant="flat-primary"
                class="btn-icon"
                block
              >
                <b-media no-body>
                  <b-media-aside class="mt-1">
                    <feather-icon
                      size="24"
                      icon="DivideIcon"
                      class="text-success"
                    />
                  </b-media-aside>
                  <b-media-body
                    :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start  ml-1' : '' }`"
                  >
                    <div>
                      <h3 class="font-weight-bolder mb-0">
                        {{ resumo.totalTaxas.toFixed(2) }}
                      </h3>
                      <p class="mb-0 text-secondary">
                        {{ $t('futures.relatorio.fechamento.totalTaxa') }} <small class="text-secondary">(USDT)</small>
                      </p>
                    </div>
                  </b-media-body>
                </b-media>
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <!-- Table Container Card -->
      <b-card
        no-body
      >
        <b-table
          ref="refListTable"
          :items="dataList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('futures.emptyText')"
          :sort-desc.sync="isSortDirDesc"
          striped
        >

          <template #cell(paridade)="data">
            <div class="text-nowrap">
              <b-avatar
                size="sm"
                :src="getIcon(data.item.moedaTrade)"
              />
              <span class="mr-1">
                {{ data.item.moedaTrade }}<span class="font-weight-light">/{{ data.item.paridadeTrade }}</span>
              </span>
            </div>
          </template>

          <template #cell(ordemPosition)="data">
            <div class="text-nowrap">
              {{ data.value }}
              <b-badge variant="light-warning">
                {{ data.item.alavancagem }}x
              </b-badge>
            </div>
          </template>

          <template #cell(totalCompra)="data">
            {{ toFixed(data.value, 4) }}
          </template>

          <template #cell(total)="data">
            {{ toFixed(data.value, 4) }}
          </template>

          <template #cell(resultado)="data">
            <template v-if="data.item.ordemPosition=='Long'">
              <b-badge :variant="parseFloat(data.item.valorEncerrado)>=parseFloat(data.item.precoCompra) ? 'light-success' : 'light-danger'">
                {{ toFixed(data.item.pnl,4) }} {{ data.item.paridadeTrade }}
                <br>
                <small>
                  ({{ toFixed(data.item.roi, 2) }}%)
                </small>
              </b-badge>
            </template>
            <template v-else>
              <b-badge :variant="parseFloat(data.item.precoCompra)>=parseFloat(data.item.valorEncerrado) ? 'light-success' : 'light-danger'">
                {{ toFixed(data.item.pnl,4) }} {{ data.item.paridadeTrade }}
                <br>
                <small>
                  ({{ toFixed(data.item.roi, 2) }}%)
                </small>
              </b-badge>
            </template>
          </template>

          <template #cell(lucroLiquido)="data">
            <template v-if="data.item.ordemPosition == 'Long'">
              <b-badge :variant="parseFloat(data.value) >=0 ? 'light-success' : 'light-danger'">
                {{ toFixed(data.value, 4) }} {{ data.item.paridadeTrade }}
              </b-badge>
            </template>
            <template v-else>
              <b-badge :variant="0 <= parseFloat(data.value) ? 'light-success' : 'light-danger'">
                {{ toFixed(data.value, 4) }} {{ data.item.paridadeTrade }}
              </b-badge>
            </template>
          </template>

          <template #cell(taxaTotal)="data">
            <template v-if="data.value">
              {{ toFixed(data.value, 6) }} <small>USDT</small>
            </template>
          </template>

          <template #cell(dataCriacaoCompra)="data">
            {{ formatDateTimeSemAnoBR(data.value) }}
          </template>

          <template #cell(dataExecucao)="data">
            {{ formatDateTimeSemAnoBR(data.value) }}
          </template>

          <template #cell(agente)="data">
            {{ data.value }} <br>
            <b-badge variant="light-primary">
              #{{ data.item.sinalId }}
            </b-badge>
          </template>

        </b-table>

        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ $t('futures.textMuted', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalCount"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>

      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BCard, BTable, BAvatar, BPagination, BRow, BCol, BOverlay, BBadge, BCardHeader, BCardBody, BButton, BMedia, BMediaAside, BMediaBody, VBTooltip, BCardTitle, BFormInput,
} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateTimeSemAnoBR, percentDiferenca, toIsoStringTimeZone } from '@/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import Ripple from 'vue-ripple-directive'
import fechamentoStoreModule from './fechamentoStoreModule'

const RELATORIO_FECHAMENTO_FUT_STORE_MODULE_NAME = 'fechamento-relatorio-fut'

export default {

  components: {
    BCard,
    BTable,
    BAvatar,
    BPagination,
    BRow,
    BCol,
    BBadge,
    BCardHeader,
    BCardBody,
    flatPickr,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardTitle,
    BOverlay,
    BFormInput,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      Portuguese,
      formatDateTimeSemAnoBR,
      percentDiferenca,
      loadingOverlayGrid: false,
      perPage: 10,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'dataExecucao,desc',
      isSortDirDesc: true,
      dataList: [],
      dataBuscaFilter: {
        coin: null,
        inicio: new Date(new Date().getFullYear(), new Date().getMonth(), '01'), // primeiro dia do mes atual
        fim: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      },
      resumo: {
        totalPorcent: 0,
      },
    }
  },

  computed: {
    tableColumns() {
      return [
        { key: 'paridade', label: this.$i18n.t('futures.relatorio.fechamento.tableHeader.paridade') },
        { key: 'ordemPosition', label: this.$i18n.t('futures.relatorio.fechamento.tableHeader.ordemPosition') },
        { key: 'precoCompra', label: this.$i18n.t('futures.relatorio.fechamento.tableHeader.precoCompra') },
        { key: 'valorEncerrado', label: this.$i18n.t('futures.relatorio.fechamento.tableHeader.valorEncerrado') },
        { key: 'quantidade', label: this.$i18n.t('futures.relatorio.fechamento.tableHeader.quantidade') },
        { key: 'taxaTotal', label: this.$i18n.t('futures.relatorio.fechamento.tableHeader.taxaTotal') },
        { key: 'resultado', label: this.$i18n.t('futures.relatorio.fechamento.tableHeader.pnl') },
        { key: 'lucroLiquido', label: this.$i18n.t('futures.relatorio.fechamento.tableHeader.lucroLiquido') },
        { key: 'dataCriacaoCompra', label: this.$i18n.t('futures.relatorio.fechamento.tableHeader.dataCriacaoCompra') },
        { key: 'dataExecucao', label: this.$i18n.t('futures.relatorio.fechamento.tableHeader.dataExecucao') },
        { key: 'agente', label: this.$i18n.t('futures.relatorio.fechamento.tableHeader.trader') },
      ]
    },
    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },

    windowWidth() {
      return this.$store.state.app.windowWidth
    },
  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(RELATORIO_FECHAMENTO_FUT_STORE_MODULE_NAME)) store.registerModule(RELATORIO_FECHAMENTO_FUT_STORE_MODULE_NAME, fechamentoStoreModule)
    this.fetchList()
  },

  beforeDestroy() {
    if (store.hasModule(RELATORIO_FECHAMENTO_FUT_STORE_MODULE_NAME)) store.unregisterModule(RELATORIO_FECHAMENTO_FUT_STORE_MODULE_NAME)
  },

  methods: {

    buscarFiltro() {
      if (this.isDataRangeDias(this.dataBuscaFilter.inicio, this.dataBuscaFilter.fim, 90)) {
        this.page = 1
        this.fetchList()
      }
    },

    refresh() {
      this.buscarFiltro()
    },

    fetchList() {
      this.loadingOverlayGrid = true

      let dataInicio = this.dataBuscaFilter.inicio
      if (typeof (dataInicio) === 'string' || dataInicio instanceof String) {
        dataInicio = toIsoStringTimeZone(new Date(dataInicio.split('-')[0], dataInicio.split('-')[1] - 1, dataInicio.split('-')[2]))
      }

      let dataFim = new Date(this.dataBuscaFilter.fim)

      if (typeof (dataFim) === 'string' || dataFim instanceof String) {
        const dataFimTemp = new Date(dataFim.split('-')[0], dataFim.split('-')[1] - 1, dataFim.split('-')[2])
        dataFim = new Date(dataFimTemp.setDate(dataFimTemp.getDate() + 1)).toISOString()
      } else {
        dataFim = new Date(dataFim.setDate(dataFim.getDate() + 1))
      }

      store
        .dispatch('fechamento-relatorio-fut/getPosicoesResumo', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          dataInicio,
          dataFim,
          status: '3',
          coin: this.dataBuscaFilter.coin,
        })
        .then(response => {
          this.resumo = response.data
        })

      store
        .dispatch('fechamento-relatorio-fut/getPosicoes', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          dataInicio,
          dataFim,
          status: '3',
          coin: this.dataBuscaFilter.coin,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.loadingOverlayGrid = false
        })
    },
  },

}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
